// Created By MK

// React Imports
import { useContext } from "react";

// MUI Imports
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Components Imports
import CustomBtn from "../../../CustomComponents/CutomButton"
import { DialogContext } from '../../../Contexts';

// Style Imports
import landingStyle from "./landing.style";

const HeroSection = () => {

    // Hooks
    const { setOpen } = useContext(DialogContext);

    return (
        <Box sx={landingStyle.heroMain}>
            <Box sx={landingStyle.heroSubBx1}>
                <Typography sx={landingStyle.heroSubBx1Txt1}>
                    Never pay for your online orders with our FREE PLAN!
                </Typography>
                <Typography sx={landingStyle.heroSubBx1Txt2}>
                    Get your restaurant landing page and online for zero dollars.
                </Typography>
                <Box sx={landingStyle.btnBx}>
                    <CustomBtn
                        sx={landingStyle.cusBtn}
                        title={'Get Started Free!'}
                        onClick={() => {
                            // setOpen(true)
                            window.location.href = 'https://nento.com/get-in-touch/#contactus'
                        }}
                    />
                </Box>
                <Typography
                    variant="h1"
                    sx={landingStyle.heroSubBx1Txt3}
                >
                    *No Credit Card required
                </Typography>
            </Box>

            <Box sx={landingStyle.heroSubBx2}>
                <Box component={'img'} src='/images/hero.png' sx={landingStyle.heroImg} />
            </Box>
        </Box>
    )
}

export default HeroSection;
