// Created By MK

// React Imports
import { useContext } from "react";

// MUI Imports
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

// Components Imports
import { DialogContext } from '../../../Contexts';
import CustomBtn from "../../../CustomComponents/CutomButton"

// Style Imports
import landingStyle from "./landing.style";

const GetStartedPage = () => {

    // Hooks
    const { setOpen } = useContext(DialogContext);

    return (
        <Grid container sx={landingStyle.gspMain} spacing={4}>
            <Grid item xs={12} md={6} sx={landingStyle.gspG1}>
                <Box component={'img'} src='/images/gettingStarted.png' sx={landingStyle.gspG1Img} />
            </Grid>
            <Grid item xs={12} md={6} sx={landingStyle.gspG2}>
                <Box>
                    <Typography sx={landingStyle.gspG2Txt1}>
                        GET STARTED
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt2}>
                        Don’t wait: Begin accepting your initial online orders within minutes
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt3}>
                        Getting started is easy:
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt4}>
                        1. Create an account effortlessly.
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt4}>
                        2. Download your menu with our widget.
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt4}>
                        3. Choose either online ordering, a website, or both for <Box component={'span'} sx={landingStyle.gspG2Span}>FREE.</Box>
                    </Typography>
                    <Typography sx={landingStyle.gspG2Txt5}>
                        It’s straightforward to have your own online food ordering website by tonight.
                    </Typography>
                    <Box sx={landingStyle.cmnBtnBx}>
                        <CustomBtn
                            title="Get Started Free!"
                            sx={landingStyle.cmnBtn}
                            onClick={() => {
                                // setOpen(true)
                                window.location.href = 'https://nento.com/get-in-touch/#contactus'
                            }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default GetStartedPage