// Created By MK

// React Imports
import { useContext } from "react"

// MUI Imports
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// Custom Imports
import { DialogContext } from "../../../Contexts"
import CustomBtn from "../../../CustomComponents/CutomButton"

// Style Imports
import landingStyle from "./landing.style"

const FreeTools = () => {

    // Hooks
    const { setOpen } = useContext(DialogContext);

    const features = [
        "No Credit Card Required",
        "Effortless Online Food Ordering System",
        "Customizable Restaurant Landing Page"
    ];

    return (
        <Grid container sx={landingStyle.ftMain} spacing={6}>
            <Grid item xs={12} md={6} sx={landingStyle.ftG1}>
                <Box component={'img'} src='/images/freeTools.png' sx={landingStyle.ftG1Img} />
            </Grid>
            <Grid item xs={12} md={6} sx={landingStyle.ftG2}>
                <Box>
                    <Typography sx={landingStyle.gspG2Txt1}>
                        FREE TOOLS
                    </Typography>

                    <Typography sx={landingStyle.gspG2Txt2}>
                        Free Online Food Ordering System and Landing Page
                    </Typography>

                    <Typography sx={landingStyle.ftG2Txt1}>
                        Empower your restaurant with essential digital tools at no cost – our online food ordering system and customized landing page are here to help you attract more customers and streamline operations.
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        {features.map((feature: string, index: number) => (
                            <Box key={index} sx={landingStyle.ftG2Bx1}>
                                <Box component={'img'} src='/images/checkbox.svg' sx={landingStyle.ftG2CheckBx} />
                                <Typography sx={landingStyle.ftG2CheckTxt}>
                                    {feature}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={landingStyle.cmnBtnBx}>
                        <CustomBtn
                            title="Get Started Free!"
                            sx={landingStyle.cmnBtn}
                            onClick={() => {
                                // setOpen(true)
                                window.location.href = 'https://nento.com/get-in-touch/#contactus'
                            }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default FreeTools