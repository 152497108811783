// Created By MK

// React Imports
import * as React from 'react';

// MUI Imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

// Third Party Imports
import { useNavigate } from 'react-router-dom';

// Custom Imports
import CustomBtn from '../../CustomComponents/CutomButton';

// Style Imports
import headerStyle from './Header.style';
import { DialogContext } from '../../Contexts';

const Header = () => {

    // Hooks
    const navigate = useNavigate()
    const { setOpen } = React.useContext(DialogContext);

    return (
        <Box>
            <CssBaseline />
            <AppBar sx={headerStyle.main}>
                <Toolbar>
                    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Box component={'img'} src='/images/logo.svg' alt='Logo' sx={{ height: '2.313rem', cursor: 'pointer' }} onClick={() => { navigate('/') }} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: "center", justifyContent: 'flex-end' }}>
                        <CustomBtn title={'Get Started Free!'}
                            sx={{
                                padding: '0.5rem 1.125rem', fontSize: '0.938rem', fontWeight: '500'
                            }}
                            onClick={() => {
                                // setOpen(true)
                                window.location.href = 'https://nento.com/get-in-touch/#contactus'
                            }}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header
